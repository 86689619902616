export const styles = () => ({
    root: {
        width: "100%",
        margin: "0",
        "& fieldset":{
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderColor: 'transparent !important'
        },
        "&:hover fieldset": {
            borderRadius: "4px",
            border: '1px solid #717171 !important',
        },
        "& .MuiOutlinedInput-root":{
            background: '#FFFFFF',
            '&.Mui-focused fieldset': {
                borderRadius: "4px",
                border: '1px solid #717171 !important',
            }
        },
        "& legend":{
            maxWidth: '0 !important'
        },
        "& .MuiFilledInput-root": {
            background: '#f4f4f4'
        },
        "& .MuiFilledInput-underline:before":{
            borderBottom: 'none !important'
        },
        "& .MuiFilledInput-underline:after":{
            borderBottom: 'none !important'
        },
        "&:hover .MuiFilledInput-underline": {
            border: '1px solid #717171 !important',
        },
        "& .MuiFilledInput-underline.Mui-focused": {
            border: '1px solid #717171 !important',
        },
        "& input":{
            fontSize: "13px"
        },
        "& .MuiInputBase-input": {
            fontSize: "13px !important"
        }
    },
    date : {
        background : '#35C5EA'
    }
});