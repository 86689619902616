import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
  unsavedTab: {
    padding: "0px 16px 16px 16px !important",
    display: "flex !important",
    justifyContent: "space-between !important",
  },
  submitBtn: {
    color: " #FFFFFF !important",
    border: "2px solid #35C5EA !important",
    width: "200px",
    backgroundColor: "#35C5EA !important",
    textTransform: "none",
    "&:hover": {
      color: "#35C5EA !important",
      backgroundColor: "transparent !important",
    },
  },
  cancleBtn: {
    color: " #FFFFFF !important",
    border: "2px solid #B7BDCE !important",
    width: "200px",
    backgroundColor: "#B7BDCE !important",
    height: "40px",
    "&  .MuiButton-label": {
      fontSize: "14px",
    },
    "&:hover": {
      color: "#B7BDCE !important",
      backgroundColor: "transparent !important",
    },
  },
  dialogWrapper: {
    "& .MuiDialog-paperFullWidth": {
      width: "360px",
      borderRadius: "12px",
    },
    "& .MuiDialogContentText-root": {
      marginBottom: "0px",
    },
    "& .MuiDialogContent-root":{
        padding:"10px 16px !important",
        fontWeight:600,
    },
    "& .MuiDialogTitle-root": {
      "& .close-btn": {
        display: "inline-block",
        width: "2.5rem",
        position: "absolute",
        top: "2px",
        right: "10px",

        "& svg": {
          height: "16px",
          width: "16px",
        },
      },
    },
    "& h2": {
      fontSize: "16px",
      fontWeight:600,
    },
    "&.MuiDialogTitle-root": {
      padding: "10px 16px !important",
      borderBottom: "1px solid #B8CFE1",
    },
  },
  titleRoot: {
    "& h2": {
      fontSize: "16px",
    },
    "&.MuiDialogTitle-root": {
      padding: "10px 16px !important",
      borderBottom: "1px solid #B8CFE1",
    },
  },
  contentText:{
    fontWeight: 500, 
    color: "#000000"
  }
}));

export default useStyles;
