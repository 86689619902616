import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
    root: {
        width: "100%",
        "& .MuiAutocomplete-inputRoot": {
            padding: "18px 0 14px 18px",
            maxHeight:100,
            overflowX: "hidden",
            overflowY: "scroll",
        },
        "& .MuiTextField-root":{
            "& label":{
                width: "94%",
                // background: "#F4F4F4",
                position: "absolute",
                paddingTop: "8px",
                paddingBottom: "4px",
                top: "-5px"
            }
        },
        "& .MuiAutocomplete-endAdornment": {
            right: "0px !important"
        },
        "& .MuiAutocomplete-popupIndicator":{
            marginRight: '10px'
        },
        "& .MuiInputBase-input": {
            padding: "2px 0 0 2px !important",
            fontSize: "14px !important"
        },
        "& .MuiChip-root": {
            margin: "8px 5px 5px 0 !important",
            backgroundColor: "#EAF0FF",
            borderRadius: '16px !important',
            "&:hover": {
                backgroundColor: "#C4D4FF !important"
            }
        },
        '& label':{
            left: '4px',
        },
        "& input": {
            fontSize: "16px",
            fontWeight: "600",
            appearance:'textfield',
            maxWidth:'95%',
            textOverflow: 'ellipsis',
            borderRadius: "8px",
            '-moz-appearance':'textfield !important',
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                appearance: "none",
                '-webkit-appearance':'textfield',
                '-moz-appearance':'textfield !important',
                margin: "0"
            },
            '&:hover , &:focus':{
                '-moz-appearance':'textfield !important',
                '-webkit-appearance':'textfield !important',
                appearance: "textfield",
            }
        },
        
        "&.multiLine": {
            minHeight: 120,
            "& .MuiAutocomplete-inputRoot": {
                minHeight: 100,
                alignItems: "flex-start",
                maxHeight:100,
                overflowX: "hidden",
                overflowY: "scroll"
            },
            "& .MuiAutocomplete-endAdornment": {
                top: "0px !important"
            },
            "& .MuiAutocomplete-input": {
                padding: "6px 3px !important",
                fontSize: "15px !important",
                margin: "3px"
            }
        },
        "& .MuiAutocomplete-option[aria-selected='true']":{
            backgroundColor: '#ffffff',
        },
        "& .MuiAutocomplete-tag":{
            maxWidth:"calc(100% - 40px) !important"
        },
        
    },
    options: {
        display: 'flex !important',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        background: '#ffffff',

        "& .MuiSvgIcon-root":{
            color: '#35C5EA'
        },
    },
    popper: {
        background: '#ffffff !important'
    }
}))

export default styles;