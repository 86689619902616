import { Box, Grid, Typography } from "@material-ui/core"
import styles from "./styles"
import moment from "moment";


const Followup = ({selData}) => {
    const classes = styles()
    // const requestedDate = moment(selData?.appointmentCreatedOn).local().format("DD-MMM-YYYY")
    const dateTime = moment.utc(selData?.appointmentCreatedOn).format("DD-MM-YYYY");
    const time = moment(selData?.appointmentCreatedOn).format('LT');


    return (
        <Grid container xs={12} className={classes.detailsRoot} style={{marginTop: '16px'}}>
            <Box component="div" className={classes.detailsTitle}>
                <Typography variant="h6" className={classes.details}>Followup Details</Typography>
            </Box>
            <Box component="div" className={classes.detailsContent}>
                <Grid container xs={12} className={classes.feeGrid}>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>Followup Requested On</Typography>
                        <Typography variant="h6" className={classes.header}>{dateTime}, {time}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>{'Consultation Date & Time'}</Typography>
                        <Typography variant="h6" className={classes.header}>{selData?.date}, {selData?.slotStartTime}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={classes.detailsHeader}>Reason</Typography>
                        <Typography variant="h6" className={classes.header}>Examine Reports</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default Followup