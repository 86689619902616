import { makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles<Theme>((theme) => ({
    dateRangeWrapper:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    datePicker:{
        display: 'flex',
        alignItems: 'center',
        "& .MuiInputBase-root":{
            padding : 0,

            "& .MuiIconButton-root":{
                color: "#717171",
            },
            "& input":{
                padding: '13px 0px !important',
            }

        },
        "&:not(:last-child)":{
            marginRight:10
        }
    },

    datePickerLabel:{
        color: '#717171',
        marginRight: '10px',
        fontSize: "13px"
    }
}))

export default styles;