import * as actionTypes from "./actionTypes";

export const UpdNotifyList = (data) =>(
{
    payload : data,
    type:actionTypes.UPD_NOTIFY_LIST,
}
)

export const NewNotifyUpd = (data) =>(
    {
        payload : data,
        type:actionTypes.NEW_NOTIFY_UPDATE,
    }
    )
