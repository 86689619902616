export const listValue ={};
export const AddDoctorlist ={}
export const getRejectDoctorList = () =>
{
    return listValue;
}

export const EmptyDoctorList = () =>{
    for ( const key in listValue)
    {
        delete listValue[key];
    }
}