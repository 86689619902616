import { Box, Chip, FormLabel } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { useEffect } from 'react';
import CommonTextField from "../CommonTextField";
import styles from "./styles";
import { genericObj } from "../../../models/common";
import CheckIcon from '@mui/icons-material/Check';

// tagselector - prop is used for binding tag text
// tagclass - prop is used to customizing tag css

const CommonSearchMultiselect = ({ label, className, ...props }: genericObj) => {
    const classes = styles();

    useEffect(()=>{
        document.getElementsByClassName("MuiAutocomplete-option")
    },[])

    return (
        <>
            {props.title && <FormLabel>{props.title}</FormLabel>}
            <Autocomplete
                renderInput={(params) => (<CommonTextField {...params} label={label || ""} placeholder={props?.placeholder && (props?.value === null || props?.value?.length === 0 || props?.value === undefined) ? props?.placeholder : ""} variant={props?.variant}/>)}
                popupIcon={<ExpandMore />}
                options={props.options}
                {...props}
                classes={{ option: classes.popper }} 
                className={clsx(classes.root, className, props.multiline ? "multiLine" : "")}
                renderOption={(props:any, option:any) => {
                    return <Box className={classes.options}>
                                <div>{props.name}</div>
                                <div>
                                    {option.selected ? <CheckIcon /> : ''}
                                </div>
                            </Box>
                }}
                renderTags={(value: any, getTagProps: any) =>
                    value.map((option: any, index: any) => {
                      return <Chip
                                label={option.name}
                                {...{ ...getTagProps({ index }) }}
                            />   
                    })
                }
            />
        </>);
}

export default CommonSearchMultiselect;
