import { ClickAwayListener, Popper } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import styles from "./styles";
interface ICustomPopover {
  name: string;
  children: React.ReactNode;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  className?:string,
  classes?:any,
  onScroll?:(e:any) => void;
}
const CustomPopover = ({ name, anchorEl, handleClose, children, className, onScroll, ...props }: ICustomPopover) => {
  const classes = styles()

  
  const open = Boolean(anchorEl);
  const id = open ? name : undefined;

  const handleClickAway = () =>{
    anchorEl && handleClose()
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Popper
          id={id}
          open={open}
          placement={"bottom-start"}
          anchorEl={anchorEl}
          className={clsx(classes.root, className)}
          onScroll={onScroll}
          {...props}
        >
          {children}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default CustomPopover;
