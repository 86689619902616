import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles from './styles'

interface ILandingItems {
  icon: React.ReactElement,
  title: string,
  children?: React.ReactElement | never[]
}
const index = ({ icon, title, children }: ILandingItems) => {
  const classes = styles();
  return (
    <>
      <Box p={8} component="div" className={classes.landingCardWrap}>
        <Box mb={3} component={"div"} className={classes.icon}>
          {icon}
        </Box>
        <Typography variant="body1" className={classes.title}>{title}</Typography>
        <Box mt={2}>{children}</Box>
      </Box>
    </>
  )
}

export default index;