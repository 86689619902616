import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme:any) => ({
    ActionButton:{
        color:'#000',
        cursor:'pointer',
        userSelect:'none',
        display: 'flex',
        alignItems: 'center',
        fontSize:"12px",
        '& svg':{
            marginRight:10,
            color:'#868686'
        }
    },
}))