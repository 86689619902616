import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import view from "../../../../assets/images/appointment/ic_dark-eye.svg";
import moment from "moment";
import { ITableData, IpastProps } from "../../../../models/consultation";


const PrescriptionList = ({
  presList,
  onClick,
  showForm,
  drugsList,
}: IpastProps) => {
  const classes = useStyles();
  const renderContainer = ({
    prescribed,
    totalDrugsCount,
    prescriptionUrl,
    lastUpdatedOn,
    id,
    pid,
  }: ITableData) => {
    return (
      <Grid container className={classes.prescriptionCover} key={id}>
        <Grid item xs={12} className={classes.headerWrapper}>
          <Grid item xs={4} className={classes.cidNumberCard}>
            {pid}
          </Grid>
          <Grid item xs={8}>
            <a
              href={
                prescriptionUrl?.length > 0 &&
                prescriptionUrl?.map((x: any) => x?.s3Url)[0]
              }
              target="_blank"
              rel="noopener noreferrer"
              className={classes.viewPrescribeCard}
            >
              <img className={classes.viewPrescribe} src={view} alt="preview" />
              <span>View Prescription</span>
            </a>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.tableDataCard}>
          <Grid item xs={4} spacing={2}>
            <Typography className={classes.tabArrContainer}>
              Prescribed by
            </Typography>
            <Typography className={classes.tabDataContainer}>
              {prescribed?.practitionerName}
            </Typography>
          </Grid>
          <Grid item xs={3} spacing={3}>
            <Typography className={classes.tabArrContainer}>Date</Typography>
            <Typography className={classes.tabDataContainer}>
            {moment(lastUpdatedOn,'YYYY-MM-DDTHH:mm:ssZ').format("DD-MMM-YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={3} spacing={3}>
            <Typography className={classes.tabArrContainer}>Time</Typography>
            <Typography className={classes.tabDataContainer}>
            {moment(lastUpdatedOn,'YYYY-MM-DD hh:mm A').format("hh:mm A")}
            </Typography>
          </Grid>
          <Grid item xs={3} spacing={3}>
            <Typography className={classes.tabArrContainer}>
              Medicine Count
            </Typography>
            <Typography className={classes.tabDataContainer}>
              {totalDrugsCount}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Grid item xs={12} className={classes.prescribeContainer}>
        {presList?.map(renderContainer)}
      </Grid>
      {!showForm && drugsList.length===0 && (
        <h4 onClick={onClick} className={classes.addNewButton}>
          + Add Prescription
        </h4>
      )}
    </>
  );
};

export default PrescriptionList;
