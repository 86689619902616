import { CircularProgress, ClickAwayListener, Grid, Typography } from "@material-ui/core";
import React from "react";
import CustomPopover from "../../../components/common/CustomPopover";
import styles from "./styles";
import clsx from "clsx";
import { ControllerRenderProps } from "react-hook-form";
import { CalendarToday } from "@material-ui/icons";
import LandingCard from "../../../components/LandingCard";
import { IPatientDetail } from ".";
import { IAppointmentPayload } from "../../../models/common";

interface IPatient {
  patientDetail: IPatientDetail;
  patientId: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  gender: string;
}

interface IPatientListing {
  id: string;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  patientList: IPatient[];
  facilityUsersListFetching: boolean;
  controlProps: ControllerRenderProps<Record<string, any>>;
  setSelectedPatient: (value: React.SetStateAction<IPatientDetail | null>) => void;
  setIsDisabled: (value: React.SetStateAction<boolean>) => void;
  appointmentPayload: IAppointmentPayload;
  setAppointmentPayload: (value: React.SetStateAction<IAppointmentPayload>) => void;
  setSelectedfamilyMember?: (value: any) => void;
  tablePage: number;
  setTablePage: (value: React.SetStateAction<number>) => void;
  hasNext?: boolean;
}

const PatientListing = ({
  id,
  anchorEl,
  handleClose,
  patientList,
  facilityUsersListFetching,
  controlProps,
  setSelectedPatient,
  setIsDisabled,
  appointmentPayload,
  setAppointmentPayload,
  tablePage,
  setTablePage,
  hasNext
}: IPatientListing) => {
  const classes = styles();

  return (
    <CustomPopover
      name={id}
      anchorEl={anchorEl}
      handleClose={()=>{}}
      className={classes.patientList}
      onScroll={(event: any) => {
        if (hasNext) {
          setTablePage(tablePage + 1);
        }
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Grid container xs={12}>
          {patientList.length > 0 &&
            patientList.map((patient: IPatient, index: number) => {
              return (
                <Grid
                  item
                  container
                  className={classes.patientListItem}
                  alignItems="center"
                  xs={12}
                  onClick={() => {
                    delete appointmentPayload.isFamily;
                    delete appointmentPayload.familyMember;
                    setAppointmentPayload({ ...appointmentPayload, user: patient.patientId, mobile: patient?.patientDetail?.mobile });
                    setSelectedPatient({ ...patient?.patientDetail });
                    handleClose();
                    setIsDisabled(false);
                    controlProps.onChange(`${patient.firstName} ${patient.lastName}`);
                  }}
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={clsx(classes.userDisplayPic)}
                  >
                    {patient?.firstName[0]?.toUpperCase() + patient?.lastName[0]?.toUpperCase()}
                  </Grid>
                  <Grid item container xs direction="column">
                    <Grid item xs={12}>
                      <Typography className="patientName">
                        {patient?.firstName}
                        &nbsp;
                        {patient?.lastName}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography className="mobile">{patient.mobileNumber}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          {patientList.length < 1 && (
            <Grid container xs={12} className={classes.noPatient} direction={"column"} alignItems="center">
              <LandingCard
                icon={facilityUsersListFetching ? <CircularProgress /> : <CalendarToday fontSize="large" />}
                title={
                  facilityUsersListFetching
                    ? "Loading! Please wait..."
                    : "No matching patient found. Try search different patient name."
                }
              >
                {/* {!facilityUsersListFetching ? (
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.title}
                    onClick={() => {
                        setIsDisabled(true);
                        setIsAddPatient(true);
                        handleClose();
                      
                    }}
                  >
                    {"+ Add Patient"}
                  </Typography>
                ) : (
                  <></>
                )} */}
              </LandingCard>
            </Grid>
          )}
        </Grid>
      </ClickAwayListener>
    </CustomPopover>
  );
};

export default PatientListing;
