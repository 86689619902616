import { makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles<Theme>((theme) => ({
    icon: {
        background: "#F7F7F7",
        width: 110,
        height: 110,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%'
    },
    landingCardWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'        
    },
    title: {
        color: "#747474",
        textAlign: "center"
    }
}))

export default styles;