export const styles = (theme:any) => ({
    root: {
        width: "100%",
        "& .MuiInputBase-root": {
            borderRadius: "4px"
        },
        "& label": {
            color: `${theme.palette.input.labelMain} !important`,
            left: '4px',
            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, 6px) scale(0.75)"
            }
        },
        "& input": {
            padding: "20px 13px 15px 15px",
            color: theme.palette.input.textMain,
            fontSize: "14px",
            fontWeight: "600",
            appearance:'textfield',
            maxWidth:'95%',
            textOverflow: 'ellipsis',
            borderRadius: "4px",
            '-moz-appearance':'textfield !important',
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                appearance: "none",
                '-webkit-appearance':'textfield',
                '-moz-appearance':'textfield !important',
                margin: "0"
            },
            '&:hover , &:focus':{
                '-moz-appearance':'textfield !important',
                '-webkit-appearance':'textfield !important',
                appearance: "textfield",
            },
            "&::-ms-reveal,&::-ms-clear": {
                display: "none",
              }
        },
        "& textarea": {
            color: theme.palette.input.textMain,
            padding: "18px 18px 14px 18px",
            fontSize: "14px",
            fontWeight: "600",
            '&:hover , &:focus':{
                appearance: "textfield",
                '-webkit-appearance':'none',
                '-moz-appearance':'textfield !important',
            }
        },
        '& fieldset': {
            border: '1px solid rgba(0, 0, 0, 0.23) !important',
        },
        "&:hover fieldset": {
            borderRadius: "4px",
            border: '1px solid #717171 !important'
        },
        "& .showhidebtn": {
            padding: "0",
            "&:hover": {
                backgroundColor: "unset"
            },
            "& svg": {
                fontSize: "22px",
                color: "#c3cdda"
            }
        },
        "& .MuiOutlinedInput-root": {
            background: '#FFFFFF',
            border: '1px solid #E4E4E4 !important',
            "& fieldset":{
                border: 'none !important',
            },
            '&.Mui-focused fieldset': {
                border: 'none !important',
            } 
        },
        "& .MuiFilledInput-root": {
            background: '#f4f4f4',
            border: '1px solid transparent !important'
        },
        "& .MuiFilledInput-underline:before":{
            borderBottom: 'none !important'
        },
        "& .MuiFilledInput-underline:after":{
            borderBottom: 'none !important'
        },
        "&:hover .MuiFilledInput-underline": {
            border: '1px solid #717171 !important'
        },
        "& .MuiFilledInput-underline.Mui-focused": {
            border: '1px solid #717171 !important'
        },
        "& .Mui-disabled":{
            opacity: '0.6'
        },
        "& legend":{
            maxWidth: '0 !important'
        },
        "& .MuiFilledInput-input": {
            paddingLeft: '18px'
        },
        "& .MuiOutlinedInput-multiline": {
            padding: '0px !important'
        },
        "&:hover .MuiOutlinedInput-root": {
            border: '1px solid #717171 !important'
        },
    }
});