import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    color: "#3c4f69"
  },
  palette: {
    primary: {
      main: "#32529D",
      background: "#6fcf97",
      borderRadius: "10px",
      padding: "15px",
      color: "#FFFFFF",
      borderStyle: "1px solid #ECECEC",
      paddingLeft: 40,
      paddingRight: 40,
      marginTop: 2,
      marginBottom: 2,
      "&:hover": {
        background: "#6fcf97",
      },
    },
    background: {
      main: "#32529D",
      menuHover: "#142E6C",
      buttonBackgroundDark: "#69798f"
    },
    icons: {
      main: "#59bad1",
      hover: "#3c4f69",
    },
    text: {
      mainLableBold: "#32529D",
      white: "#fff",
      sideBarLink: "#F2F3F5",
      info: '#0048FF',
      danger: '#E21A00',
      warning: '#FD8E00',
      success: '#07C180'
    },
    input: {
      borderMain: "#DFDFDF",
      textMain: "#000000",
      labelMain: "#717171",
      background: "#F4F4F4"
    },
    table: {
      headBackground: "#3c4f69",
      evenRow: "#f7f7f7",
      textMain: "#3c4f69",
      rowHover: "#f0fcff"
    },
    modal: {
      headerBackgroundColor: "#3c4f69",
      headerColor: "white"
    }
  },
  consultation:{
    brand: '#E22525',
    footerHeight: 72,
    mobileFooterHeight: 56,
    sidebarWidth: 300,
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 2,
    mobileTopBarHeight: 52,
    rightDrawerWidth: 320,
  }
});

export default theme;
