export const styles = (theme) => ({
    root: {
        width: "100%",
        // backgroundColor: "#f7f7f7",
        "& .empty-content": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            flexDirection: "column",
            minHeight: "150px",
            "& .MuiSvgIcon-root": {
                color: theme.palette.text.mainLableBold,
                fontSize: "30px",
            },
            "& h5": {
                color: theme.palette.text.mainLableBold,
                fontSize: "16px",
                margin: "0",
                fontWeight: "400"
            }
        }
    }
});