import Button from '@material-ui/core/Button';
import MicIcon from '../../../../assets/images/consultation/AudioOn.svg';
import MicOffIcon from '../../../../assets/images/consultation/AudioOff.svg';

import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import Switch from '@mui/material/Switch';
import { Grid, Typography } from '@material-ui/core';
import useDevices from '../../../../hooks/useDevices/useDevices';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string; roomState?:any}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { audioInputDevices } = useDevices();

  return (
    props.roomState === 'disconnected' ?
    (<>
      <Grid direction="column" style={{ display: 'flex' }}>
        <h2 style={{ color: '#ffffff', margin: '0 0 8px 0' }}>Audio</h2>
        <Typography style={{ color: '#ffffff' }}>{`Your Microphone is ${isAudioEnabled ? 'Enabled' : 'Disabled'}`}</Typography>
      </Grid>
      <Switch
        className={props.className}
        checked={isAudioEnabled}
        onChange={toggleAudioEnabled}
        disabled={!audioInputDevices || props.disabled}
        disableRipple
        inputProps={{ 'aria-label': 'ant design' }}
        data-cy-audio-toggle
      />
    </>) : <Button
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!audioInputDevices || props.disabled}
      startIcon={isAudioEnabled ? <img src={MicIcon} /> : <img src={MicOffIcon} />}
      data-cy-audio-toggle
    >
    </Button>
  );
}
