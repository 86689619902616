import { makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles<Theme>((theme) => ({
  root: {
    width: "342px !important",
    backgroundColor: "white",
    zIndex: 9999,
    borderRadius: "12px",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
    padding: "8px 4px"
},
}));

export default styles