import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
    footerLogo:{
        position: "fixed",
        bottom: 0,
        width: "150px",
        "& img": {
          maxWidth: "100%"
        },
      },
      mainContainer:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "24px"
      },
      contentContainer:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      errNumber:{
        fontWeight: 700,
        fontSize: "36px"
      },
      contentText:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      btnContainer:{
        display: "flex",
        gap: "1rem"
      }
}));

export default useStyles;