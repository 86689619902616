import { makeStyles } from "@material-ui/core";
import React from "react";
import { styles } from "./styles";

const useStyles = makeStyles(styles);
const LayoutContainer = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <div {...props} className={classes.root}>
      {children}
    </div>
  )
}

export default LayoutContainer;
