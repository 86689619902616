export const styles = (theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "10000",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
});