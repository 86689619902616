import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core';
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import clsx from 'clsx';
import CustomTextField from '../CustomTextField';
import { styles } from "./styles";
const useStyles = makeStyles(styles);
const CommonDatePicker = (props:any) => {
    const classes = useStyles();
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} >
            <KeyboardDatePicker
                maxDateMessage={null}
                invalidDateMessage={null}
                minDateMessage={null}
                TextFieldComponent={CustomTextField}
                {...props}
                className={props?.className ? clsx(classes.root, props?.className) : classes.root}
                variant="inline"
                margin="normal"
                autoOk
                inputVariant={props?.variant ? props?.variant : 'filled'}
            />
        </MuiPickersUtilsProvider>);
}

export default CommonDatePicker;