import * as actionTypes from "../actions/actionTypes";
const initialState = {
  pickup: false,
  chatnow: false,
};
const call = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CALL_PICK_UP:
      const pickup = action.pickup;
      return { ...state, pickup: pickup };

    case actionTypes.CHAT_NOW:
      const chatnow = action.chatnow;
      return { ...state, chatnow: chatnow };
      
    default:
      return state;
  }
};
export default call;
