import { AxiosInstance } from "axios";
import { NOTIFICATION_API } from "../../config/url.config";
import Services from "../services";

const instance: AxiosInstance = new Services().getServiceInstance();


export const getNotificationDetails = async (key:string, params: any) =>{
    const data = await instance.get(`${NOTIFICATION_API}/getNotifications?practitionerId=${params.practitionerId}&pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&skip=${params.skip}`);
    return data;
  };

  export const markAllNotification = async (params:any) =>{
    const data = await instance.post(`${NOTIFICATION_API}/markAllNotificationCompleted`,{practitionerId:params.practitionerId});
    return data;
}  