import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
  root: {
    "& .makeStyles-drawerOpen": {
      display: "none",
    },
  },
  container: {},
  baseContainer: {
    background: "#32529D",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: "0px",
    overflow: "hidden",
  },
  logo: {
    width: "52%",
    height: "100%",
  },
  footerLogo: {
    position:"relative",
    top:"5vh",
      },
  content: {
    height: "100vh",
    minHeight: "100%",
    overflow: "overlay",
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
