import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import { useState } from "react";
import CustomIconButton from "../../../components/common/CustomIconButton";
import Download from "../../../assets/images/icons/download.svg";
import View from "../../../assets/images/icons/view.svg";
import styles from "./styles";
import { downloadFile } from "../../../utils";
import DocumentViewer from "../../../components/common/DocumentViewer";
interface IdetailProps {
  followUpDetail: any | undefined;
  detailData: any;
}

const Attachments = ({ followUpDetail, detailData }: IdetailProps) => {
  const { records } = followUpDetail || detailData || {};
  const classes = styles();
  const [showDoc, setShowDoc] = useState<any>("");
  const onclickToView = (item: any) => {
    setShowDoc(item?.path);
  };
  return (
    <Grid
      container
      xs={12}
      className={classes.detailsRoot}
      style={{ marginTop: "16px" }}
    >
      <Box
        component="div"
        className={classes.detailsTitle}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h6" className={classes.details}>
          Attachments
        </Typography>
        {/* <CustomIconButton
          icon={
            <img
              src={Download}
              style={{ height: "20px", marginRight: "8px" }}
            />
          }
          label="Download All"
          onClick={() => {}}
          variant="text"
          size="small"
          labelFont={16}
          classes={classes.download}
        /> */}
      </Box>
      {records?.length > 0?
      <Box component="div" className={classes.detailsContent}>
        {records?.map((item: any) => (
          <Grid
            container
            xs={12}
            className={classes.feeGrid}
          >
            <Grid item xs={5} md={6}
             className={classes.RecordName}>
             <Tooltip title={item.name}> 
             <span className={classes.feeContent}>{item.name}</span>
             </Tooltip>
            </Grid>
            <Grid item xs={3} md={2}>
              <span className={classes.downloadTag}>{item.tags}</span>
            </Grid>
            <Grid item >
              <CustomIconButton
                icon={<img src={View}  />}
                label=""
                onClick={onclickToView.bind(null, item)}
                variant="text"
                size="small"
                labelFont={16}
                classes={classes.view}
              />
              <CustomIconButton
                icon={<img src={Download}  />}
                label=""
                onClick={async () => {
                  const fileName = item?.name;
                  await downloadFile(item?.path, fileName);
                }}
                variant="text"
                size="small"
                labelFont={16}
                classes={classes.download}
              />
            </Grid>
          </Grid>
        ))}
      </Box>: <div className={classes.noAttach}>No Attachments</div>
}
      {showDoc && (
        <DocumentViewer
          open={showDoc}
          onClose={() => {
            setShowDoc(null);
          }}
          title={"View Document"}
          documents={[{ uri: showDoc }]}
        />
      )}
    </Grid>
  );
};

export default Attachments;
