import { makeStyles } from "@material-ui/core";
import { InboxOutlined } from "@material-ui/icons";
import React from "react";
import { styles } from "./styles";

const useStyles = makeStyles(styles);
const EmptyPlaceholder = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="empty-content">
        <InboxOutlined />
        <h5>No Data</h5>
      </div>
    </div>
  )
};

export default EmptyPlaceholder;
