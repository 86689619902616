import { Grid, IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { DragIndicator, KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@material-ui/icons";
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import { Pagination, PaginationItem } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getSorting, stableSort } from "../../utils/sorting";
import EmptyPlaceholder from "../common/EmptyPlaceholder";
import "./Table.css";
import TableHeader from "./TableHeader/TableHeader.js";
import { StyledTableCell, StyledTableRow, useStyles } from "./TableStyle";

const AppTable = (props) => {
  const { page, rowsPerPage, order, orderBy, isLoading, dragSort, onDragEnd, extra } = props;
  const classes = useStyles();
  const [tableCurrentPage, setTableCurrentPage] = React.useState(page);
  const [tableRowsPerPage, setTableRowsPerPage] = React.useState(rowsPerPage);
  const [tableOrder, setTableOrder] = React.useState(order); //asc or desc
  const [tableOrderBy, setTableOrderBy] = React.useState(orderBy); //property name

  useEffect(() => {
    setTableCurrentPage(page);
    setTableRowsPerPage(rowsPerPage);
    setTableOrder(order);
    setTableOrderBy(orderBy);
  }, [page, rowsPerPage, order, orderBy]);

  //execute on every page change
  const handleChangePage = (event, newPage) => {
    if (!props.paginationServerSide) {
      setTableCurrentPage(newPage);
    } else {
      props.onPageChange(newPage);
    }
  };

  //execute when we change rows per page dropdown
  const handleChangeRowsPerPage = (event) => {
    if (!props.paginationServerSide) {
      setTableRowsPerPage(parseInt(event.target.value, 10));
      setTableCurrentPage(0);
    } else {
      props.onRowsPerPageChange(parseInt(event.target.value, 10));
    }
  };

  //execute on sorting
  const handleRequestSort = (event, property) => {
    const isDesc = tableOrderBy === property && tableOrder === "desc";
    const direction = isDesc ? "asc" : "desc";
    if (!props.sortingServerSide) {
      setTableOrder(direction);
      setTableOrderBy(property);
    } else {
      props.onSortChange(direction, property);
    }
  };

  const handleRowclick = (row) => {
    if (props?.rowclickable) {
      props?.onRowClickHandler(row);
    }
  };

  let tableData = props.tableData;
  if (props.sortingRequired && !props.sortingServerSide) {
    tableData = stableSort(tableData, getSorting(tableOrder, tableOrderBy));
  }

  if (props.paginationRequired && !props.paginationServerSide) {
    tableData = tableData.slice(
      tableCurrentPage * tableRowsPerPage,
      tableCurrentPage * tableRowsPerPage + tableRowsPerPage
    );
  }
  const getPaginationComponent = (item) => {
    switch (item.type) {
      case "first":
        return (
          <PaginationItem
            {...item}
            component={(props) => (
              <button {...props} className={`${props.className}`}><KeyboardDoubleArrowLeftRoundedIcon /></button>
            )}
          />
        );
      case "previous":
        return (
          <PaginationItem
            {...item}
            component={(props) => (
              <button {...props} className={`${props.className} navigation`}><KeyboardArrowLeftOutlined />Prev</button>
            )}
          />
        );
      case "next":
        return (
          <PaginationItem
            {...item}
            component={(props) => (
              <button {...props} className={`${props.className} navigation`}>Next<KeyboardArrowRightOutlined/></button>
            )}
          />
        );
      case "last":
        return (
          <PaginationItem
            {...item}
            component={(props) => (
              <button {...props} className={`${props.className}`}><KeyboardDoubleArrowRightRoundedIcon /></button>
            )}
          />
        );
      default:
        return <PaginationItem {...item} />;
    }
  };
  return (
    <div className="table-container position-relative">
      <Table className={classes.table} aria-label="table">
        <TableHeader
          headCells={props.tableHeader}
          order={tableOrder}
          orderBy={tableOrderBy}
          sortingRequired={props.sortingRequired}
          onRequestSort={handleRequestSort}
        />

        {/* If Drag Sort Disabled */}
        {(!dragSort || isLoading || tableData?.length == 0) &&
          <TableBody>
            {isLoading &&
              <StyledTableRow>
                <StyledTableCell
                  colSpan={props.tableHeader && props.tableHeader.length}
                  className={classes.center}
                >
                  <CircularProgress />
                </StyledTableCell>
              </StyledTableRow>
            }
            {!isLoading && tableData?.length == 0 &&
              <StyledTableRow>
                <StyledTableCell
                  colSpan={props.tableHeader && props.tableHeader.length}
                  className={classes.center}
                >
                  <EmptyPlaceholder />
                </StyledTableCell>
              </StyledTableRow>
            }
            {!isLoading && tableData?.length > 0 &&
              <>
                {
                  tableData.map((row, index) => (
                    <StyledTableRow
                      key={row.id}
                      onClick={(event) => handleRowclick(row)}
                      hover={props.rowclickable}
                    >
                      {props.tableHeader.map((headCell) => (
                        <StyledTableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "default"}
                          width={headCell?.width}
                          className={props?.tableDataClass}
                        >
                          {headCell.render && (
                            <>
                              {headCell.render(
                                row[headCell.id],
                                row,
                                index,
                                tableData
                              )}
                            </>
                          )}
                          {!headCell.render && <>{row[headCell.id]}</>}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))
                }
              </>
            }
          </TableBody>
        }

        {/* If Drag Sort Enabled */}
        {dragSort &&
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="tableDrop" direction="vertical" >
              {(droppableProvided) => (
                <TableBody
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {
                    tableData.map((row, index) => (
                      <Draggable
                        key={row.id}
                        draggableId={row.id}
                        index={index}
                      >
                        {(
                          draggableProvided,
                          snapshot,
                        ) => (
                          <StyledTableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              background: snapshot.isDragging
                                ? "rgba(245,245,245, 0.75)"
                                : "none"
                            }}
                          >
                            {props.tableHeader.map((headCell, index) => (
                              <StyledTableCell
                                key={headCell.id}
                                align={headCell.numeric ? "right" : "left"}
                                padding={headCell.disablePadding ? "none" : "default"}
                              >
                                {index === 0 &&
                                  <IconButton size="small" style={{ marginRight: "10px" }} {...draggableProvided.dragHandleProps}>
                                    <DragIndicator />
                                  </IconButton>
                                }
                                {headCell.render && (
                                  <>
                                    {headCell.render(
                                      row[headCell.id],
                                      row,
                                      index,
                                      tableData
                                    )}
                                  </>
                                )}
                                {!headCell.render && <>{row[headCell.id]}</>}
                              </StyledTableCell>
                            ))}
                          </StyledTableRow>
                        )}
                      </Draggable>
                    ))
                  }
                  {droppableProvided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        }

      </Table>
      <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "20px" }}>
        <Grid item>{extra}</Grid>
        <Grid item>
          {props.paginationRequired && (
            <div className={classes.paginationContainer}>
              <Pagination
                count={Math.ceil(
                  (!props.paginationServerSide
                    ? props.tableData.length
                    : props.totalRecords) / tableRowsPerPage
                )}
                renderItem={getPaginationComponent}
                page={tableCurrentPage || 1}
                shape="rounded"
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div >
  );
};

AppTable.defaultProps = {
  tableData: [],
  tableHeader: [],
  totalRecords: 0,
  page: 0,
  rowsPerPage: 2,
  rowsPerPageOptions: [2, 4, 6],
  paginationRequired: true,
  paginationServerSide: false,
  sortingRequired: true,
  sortingServerSide: false,
  rowclickable: false,
  extra:PropTypes.objectOf()
};

AppTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  tableHeader: PropTypes.array.isRequired,
  totalRecords: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  paginationRequired: PropTypes.bool,
  paginationServerSide: PropTypes.bool,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  sortingRequired: PropTypes.bool,
  sortingServerSide: PropTypes.bool,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  onSortChange: PropTypes.func,
  rowclickable: PropTypes.bool,
  onRowClickHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  extra:PropTypes.objectOf()
};

export default AppTable;
