import { Dialog, DialogContent, DialogTitle, IconButton, DialogActions, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { HighlightOff } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import styles from "./styles";

interface ICustomRightSlideDialog {
    title: any,
    children: any,
    onClose: any,
    size: any,
    autoWidth: any,
    CloseIcon: any,
    dialogFooter: any,
    open: boolean,
    className?: any,
    enableFooter?: any,
    disableEnforceFocus?:boolean
}

const Transition = React.forwardRef<unknown, TransitionProps>(
    (props, ref) => <Slide direction="left" ref={ref} {...props} />);

const CustomRightSlideDialog = ({ title, children, onClose, size, autoWidth, className, enableFooter, CloseIcon, dialogFooter = <span></span>, ...rest }: ICustomRightSlideDialog) => {
    const classes = styles()
    return (
        <Dialog {...rest} maxWidth={size} fullWidth={autoWidth} className={clsx(classes.root, className)}
            TransitionComponent={Transition} disableScrollLock
        >
            <DialogTitle>{title}
                <IconButton className="close-btn" onClick={onClose}>
                    {CloseIcon ? CloseIcon : <HighlightOff />}
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {
                Object.keys(dialogFooter.props).length > 0 &&
                <DialogActions>
                    {dialogFooter}
                </DialogActions>
            }
        </Dialog>
    )
}

export default CustomRightSlideDialog;