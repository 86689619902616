import * as actionTypes from "./actionTypes";
import {UPDATE_CLINIC_INFO} from './actionTypes';

export const setClinicDetails = (data) => ({
  payload: data,
  type: actionTypes.CLINIC_DETAILS,
});

export const setFacilityDetails = (data) => ({
  payload: data,
  type: actionTypes.FACILITY_DETAILS,
});

export const updateClinic = (data) => ({
  payload: data,
  type: actionTypes.UPDATE_CLINIC_INFO,
});