import { Button } from '@material-ui/core';
import VideoCollpse from '../../../../assets/images/consultation/prime_window-minimize.svg';

const VideoCollapse = ({ reference }: any) => {
  const handlePIP = () => {
    reference();
  };

  return (
    <Button
      style={{ background: '#8A8F9B', padding: '6px 8px' }}
      onClick={() => {
        handlePIP();
      }}
      data-cy-disconnect
    >
      <img src={VideoCollpse} style={{ paddingLeft: '8px' }} />
    </Button>
  );
};

export default VideoCollapse;
