import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import useStyles from "./styles";
import CloseIcon from "../../../../assets/images/consultation/CloseIcon";
import CustomSubmitButton from "../../../common/CustomSubmitButton";

const UnsavedChangesWarning = (props: any) => {
  const classes = useStyles();
  const { open, onClose, onSubmit } = props || {};
  return (
    <Dialog open={open} fullWidth={true} className={classes.dialogWrapper}>
      <DialogTitle className={classes.titleRoot}>
        Alert
        <IconButton className="close-btn" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={classes.contentText}>
            All the data will be lost if you do not finish generating the
            prescription before switching to other tab.{" "}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.unsavedTab}>
        <CustomSubmitButton
          className={classes.cancleBtn}
          isLoading={false}
          onClick={onClose}
        >
          Cancel
        </CustomSubmitButton>
        <Button
          onClick={onSubmit}
          className={classes.submitBtn}
          style={{ textTransform: "none" }}
        >
          Switch Tab
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesWarning;
