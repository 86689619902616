import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import { Button } from '@material-ui/core';
import VideoExpand from '../../../assets/images/consultation/VideoExpand.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowY: 'auto',
      background: 'rgb(79, 83, 85)',
      gridArea: '1 / 1 / 1 / 1',
      zIndex: 5,
      position: 'absolute',
      [theme.breakpoints.down('sm')]: {
        gridArea: '2 / 1 / 3 / 3',
        overflowY: 'initial',
        overflowX: 'auto',
        display: 'flex',
      },
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {
      justifyContent: 'left',
      paddingLeft: '16px',
    },
    innerScrollContainer: {
      width: `calc(300px - 3em)`,
      padding: '1.5em 0',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        padding: `8px`,
        display: 'flex',
      },
    },
  })
);

export default function ParticipantList({ isVideoCollapsed, reference }: any) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  //if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <aside
      className={clsx(classes.container, {
        [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
      })}
    >
      {participants.length !== 0 && (
        <div className={classes.scrollContainer} style={{display: !isVideoCollapsed ? 'flex' : 'none'}}>
          <div className={classes.innerScrollContainer}>
            <Participant participant={localParticipant} isLocalParticipant={true} />
            {participants.map(participant => {
              return (
                <Participant
                  key={participant.sid}
                  participant={participant}
                  isSelected={participant === selectedParticipant}
                  onClick={() => setSelectedParticipant(participant)}
                  hideParticipant={true}
                />
              );
            })}
          </div>
        </div>
      )}
      {isVideoCollapsed && (
        <Button
          style={{ background: 'rgba(0, 0, 0, 0.5)', margin: '8px' ,color:'white', textTransform: 'none'}}
          onClick={() => {
            reference();
          }}
          startIcon={<img src={VideoExpand} />}
          data-cy-disconnect
        >
          Expand
        </Button>
      )}
    </aside>
  );
}
