import * as actionTypes from "../actions/actionTypes";

const initialState = {
  sentOtpToken: "",
  user: null,
  error: null,
  loading: false,
  message: null,
  userRole: "",
  mobileData: {},
  oneTimeToken: ""
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_OTP_SUCCESS:
      return {
        ...state,
        sentOtpToken: action.payload,
        userRole: action.role,
        mobileData: action.mobileData,
      };
    case actionTypes.REGISTER_OTP_ONE_TIME_TOKEN:
      return {
        ...state,
        oneTimeToken: action.payload.oneTimeToken,
        userRole: action.payload.role,
      };
    case actionTypes.REGISTER_START:
      return { ...state, error: null, message: "", loading: true };
    case actionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case actionTypes.REGISTER_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.REGISTER_MESSAGE:
      return { ...state, message: action.message, loading: false };
    default:
      return state;
  }
};

export default register;
