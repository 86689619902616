import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: any) => ({
    notifyIcon:{
        display:'flex',
        cursor:'pointer',
        borderRadius:'50%'
    },
    notify:{
        color: "rgba(0, 0, 0, 1)",
         fontWeight:500,
         fontSize:'14px',
         backgroundColor:'none'
    },
    markAll:{
        color: "rgba(26, 31, 54, 1)",
         fontWeight:400,
        fontSize:'14px',
        cursor: "pointer",
        backgroundColor:'none' 
    },
    Content :{
        fontSize:'12px',
        fontWeight:400,
        color:"rgba(119, 119, 119, 1)",
        paddingLeft:"16px"
    },
    Time:{
        fontSize:'12px',
        fontWeight:400,
        color:"rgba(119, 119, 119, 1)",
        paddingLeft:"16px"
    },
    Type:{
        fontSize:'14px',
        fontWeight:600,
        color:"rgba(26, 31, 54, 1)",
        paddingLeft:"16px"
    },
    setNotify:{
        display:'flex',
        borderBottom: "1px solid #E6E6E6",
        cursor:'pointer',
        paddingTop:'16px',
        paddingBottom:'24px'
    },
    noBorder:{
        display:'flex',
        cursor:'pointer',
        paddingTop:'16px',
        paddingBottom:'24px'
    },
    openNotify:{
        background:"rgba(243, 247, 255, 1)" ,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          marginRight: "12px",
          height: "35px",
          width: "35px",
          padding: "5px",
    },
    noOpenNotify:{
        background:  "white",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginRight: "12px",
        height: "35px",
        width: "35px",
        padding: "5px",
    },
    profileMenu: {
        right:"2px",
        maxWidth: "458px",
        minWidth:'400px',
        maxHeight:"385px",
        minHeight:'100px',
        position: "absolute",
        backgroundColor: "#ffffff",
        top: "105%",
        textAlign: "left",
        borderBottomRightRadius: "5px",
        borderBottomLeftRadius: "5px",
        boxShadow: '0px 0px 12px -2px rgba(0, 0, 0, 0.18)',
        "&>div": {
          display: "block",
          color: theme.palette.text.sideBarLink,
          fontSize: "14px",
          lineHeight: "1.14",
          letterSpacing: "-0.28px",
          fontWeight: "500",
        },
        "&>a": {
          display: "block",
          color: theme.palette.text.sideBarLink,
          fontSize: "14px",
          lineHeight: "1.14",
          letterSpacing: "-0.28px",
          padding: "8px 20px",
          fontWeight: "500",
        },
        "& > div > a": {
          display: "block",
          color: theme.palette.text.sideBarLink,
          fontSize: "14px",
          lineHeight: "1.14",
          letterSpacing: "-0.28px",
          padding: "8px 20px",
          fontWeight: "400",
        },
        "& .EmptyMessage":{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        "& .NoMessage":{
            color: 'grey',
            fontWeight:500,
            fontSize:'14px',
            backgroundColor:'none'
        }
        },
        "& .PageNavigation":{
            overflowY:"auto",
            maxHeight:'340px',
            "& .loading":{
                width:'100%',
                display:'flex',
                justifyContent:'center',
                "& .Circular":{
                    margin:'10px'
                }
            }
        }
      },
}));

export default useStyles;