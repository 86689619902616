import { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../../assets/images/consultation/VideoOff.svg';
import VideoOnIcon from '../../../../assets/images/consultation/VideoOn.svg';

import useDevices from '../../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import Switch from '@mui/material/Switch';
import { Grid, Typography } from '@material-ui/core';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string; roomState?:any }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    props.roomState === 'disconnected' ?
    (<>
      <Grid direction="column" style={{ display: 'flex' }}>
        <h2 style={{ color: '#ffffff', margin: '0 0 8px 0' }}>Video</h2>
        <Typography style={{ color: '#ffffff' }}>{`Your Camera is turned ${isVideoEnabled ? 'On' : 'Off'}`}</Typography>
      </Grid>
      <Switch
        className={props.className}
        checked={isVideoEnabled}
        onChange={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
        disableRipple
        inputProps={{ 'aria-label': 'ant design' }}
        data-cy-video-toggle
      />
    </>) : <Button
      className={props.className}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
      startIcon={isVideoEnabled ? <img src={VideoOnIcon} /> : <img src={VideoOffIcon} />}
    >
    </Button>
  );
}
