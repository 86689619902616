export const rescheduleReasons : any = [
    {id: 'doctor_not_available', name : "Doctor not available"},
    {id: 'patient_not_available', name :  "Patient Not available"},
    {id: 'slot_overbooked', name :  "Slot overbooked"},
    {id: 'wrong_slot_booked', name :  "Wrong slot booked"},
    {id: 'others', name : "Others"}
  ]

  export const cancellationReason : any = [
    {id: 'doctor_not_available', name : "Doctor not available"},
    {id: 'patient_requested_to_cancel', name : "Patient requested to cancel"},
    {id: 'facility_closure', name : "Planned/Unplanned facility closure"},
    {id: 'others', name : "Others"}
  ]

  export const followupReason : any = [
    {id: 'test_results', name : "Test results evaluation/re-evaluation"},
    {id: 'status_of_symptoms', name : "Review of Symptoms"},
    {id: 'review_with_doctor', name : "Review with Doctor"},
    {id: 'chief_complaint', name : "Progression/Unresolved chief complaint"},
    {id: 'medication_review', name : "Medication Review"},
    {id: 'others', name : "Others"}
  ]