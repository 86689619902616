import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
// import { ReactQueryDevtools } from "react-query-devtools";

import App from "./App";
import "./index.css";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import theme from "./theme";
import AppStateProvider from "../src/state";

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <AppStateProvider>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </AppStateProvider>
        </ThemeProvider>
        {/* <ReactQueryDevtools /> */}
      </Router>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));
