import { IconButton, makeStyles, TextField } from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { genericObj } from "../../../models/common";

const useStyles = makeStyles(styles);
const CommonTextField = React.forwardRef(({ className, type, ...props }:genericObj, ref:any) => {
  const classes = useStyles();
  const [stateType, setStateType] = useState('text');
  
  const handleKeyDown = (evt:any) => {
    if(stateType === 'number'){
      let keyArray = ["e", "E", "+", "-"]
      if(props.allowDecimalValue !== undefined && !props.allowDecimalValue){
        keyArray.push(".")
      }
      keyArray.includes(evt.key) && evt.preventDefault()
    }
  }
  useEffect(() => {
    setStateType(type || 'text')
  }, [type])
  const toggleVisibility = () => {
    setStateType(stateType === 'text' ? 'password' : 'text')
  }
  if(type==='file' && props.accept){
    props["inputProps"]={accept:"image/*,.xls,.ppt"}
  }

  return (
    type === 'password' ?
      <TextField
        className={clsx(classes.root, className)}
        {...props}
        type={stateType}
        ref={ref}
        placeholder={props?.placeholder ? props?.placeholder : ''}
        variant={props?.variant ? props?.variant : 'outlined'}
        autoComplete='new-password'
        InputLabelProps={props?.value ? {shrink : props?.value} : {}}
        InputProps={{
          endAdornment: type === 'password' ? (
            <IconButton disableRipple={true} className='showhidebtn' onClick={toggleVisibility}>
              {stateType === 'text' &&
                < VisibilityOutlined />
              }
              {stateType === 'password' &&
                <VisibilityOffOutlined />
              }
            </IconButton>
          ) : null
        }}
      />  : <TextField
        className={clsx(classes.root, className)}
        {...(props.value ? { InputLabelProps: { shrink: props.value } } : {})}
        {...props}
        variant={props?.variant ? props?.variant : 'outlined'}
        label={props.label}
        placeholder={props?.placeholder ? props?.placeholder : ''}
        type={type}
        ref={ref}
        onKeyDown={(e)=> handleKeyDown(e)}
      />
  );
});

export default CommonTextField;
