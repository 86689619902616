import { ErrorMessage } from '@hookform/error-message';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { styles } from "./styles";
const useStyles = makeStyles(styles);
const CustomFormEM = React.forwardRef(({ name, render, ...props }, ref,errors) => {
    const classes = useStyles();
    return (
        <span className={classes.root}>

            <ErrorMessage
                name={name}
                render={render}
                as={<span />}
                {...props}
                ref={ref}
            />
        </span>
    );
})

export default CustomFormEM;