export const styles = (theme) => ({
    root: {
        width: "100%",
        "& .MuiInputBase-root": {
            borderRadius: "4px",
            backgroundColor: theme.palette.input.background,
        },
        "& label": {
            color: `${theme.palette.input.labelMain} !important`,
            transform: "translate(14px, 18px) scale(1)",
            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, 6px) scale(0.75)"
            }
        },
        "& input": {
            padding: "18px 10px 12px 15px !important",
            color: theme.palette.input.textMain,
            fontSize: "16px",
            fontWeight: "600",
            appearance:'textfield',
            maxWidth:'95%',
            textOverflow: 'ellipsis',
            '-moz-appearance':'textfield !important',
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                appearance: "none",
                '-webkit-appearance':'textfield',
                '-moz-appearance':'textfield !important',
                margin: "0"
            },
            '&:hover , &:focus':{
                '-moz-appearance':'textfield !important',
                '-webkit-appearance':'textfield !important',
                appearance: "textfield",
            }
        },
        "& textarea": {
            color: theme.palette.input.textMain,
            fontSize: "16px",
            fontWeight: "600",
            '&:hover , &:focus':{
                appearance: "textfield",
                '-webkit-appearance':'none',
                '-moz-appearance':'textfield !important',
            }
        },
        "& fieldset, &:hover fieldset": {
            borderRadius: "4px",
            borderColor: `${theme.palette.input.borderMain} !important`
        },
        "& .showhidebtn": {
            padding: "0",
            "&:hover": {
                backgroundColor: "unset"
            },
            "& svg": {
                fontSize: "22px",
                color: "#c3cdda"
            }
        }
    }
});