import * as actionTypes from "../actions/actionTypes";
import {UPDATE_CLINIC_INFO} from '../actions/actionTypes';

const initialState = {
  details: {},
  facilityDetails: {}
};

const clinic = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLINIC_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case actionTypes.FACILITY_DETAILS:
      return {
        ...state,
        facilityDetails: action.payload,
      };
    case actionTypes.UPDATE_CLINIC_INFO:
        return {
          ...state,
          details: Object.assign(state.details,action.payload)
        };    
    default:
      return state;
  }
};

export default clinic;
