import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const user = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_DETAILS:
      return { ...action.payload };
    case actionTypes.UPDATE_USER_MOBILE:
        return {
          ...state,
          mobile: action.mobile
        };  
    default:
      return state;
  }
};

export default user;
