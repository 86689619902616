import { makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles<Theme>((theme) => ({
    filterButtonRoot:{
        backgroundColor: '#D6DFF8',
        padding: '10px 12px',
        borderRadius: '4px',
        "&:hover":{
            backgroundColor: '#D6DFF8',
        },
        "& img":{
            height : '16px',
            width : '16px',
        },
        "& .MuiTypography-root":{
            marginLeft : '10px',
            color : '#32529D',
            fontSize: "15px"
        }
    },
    popoverRoot:{
        "& .MuiPaper-root":{
            marginTop: 5,
            maxWidth:"480px",
            borderRadius:4,
        }
    },

    //accordion
    accordionRoot:{
        boxShadow:'none',
        marginBottom:'0px !important',
        "& .Mui-expanded, .MuiCollapse-root":{
            background: '#F4F4F4',
        },
        "& .Mui-expanded":{
            "& .MuiTypography-root":{
                color:'#32529D',
            }
        },
        "& .MuiAccordionSummary-root":{
            maxHeight: "40px",
            minHeight: "48px"
        }
    },
    filterTypeTitle:{
        color:'#808A98',
        fontWeight: 500,
        fontSize: "13px"
    },
    filterActionWrapper:{
        display : 'flex',
        justifyContent:'space-between',
        padding:10,
        borderTop: "1px solid #E2E2E2",

        "& .cancelFilterBtn, .resetFilterBtn":{
            "& .MuiTypography-root":{
                fontWeight: 500,
                color: '#717171'
            },
            "&:hover":{
                backgroundColor: 'transparent'
            },
        },
        "& .applyFilterBtn":{
            marginLeft: 5
        }
    },
}))

export default styles;