import * as actionTypes from "./actionTypes";

export const setPickUp = (data) => ({ 
    type:actionTypes.CALL_PICK_UP,
    pickup: data.pickup,
  }) 

export const setChatNow = (data) => ({ 
  type:actionTypes.CHAT_NOW,
  chatnow: data.chatnow,
}) 