export const styles = (theme) => ({
    root: {
        "& .routerLink": {
            color: "inherit",
            fontWeight: "400",
            fontSize: "13px"
        },
        "& .MuiTypography-root": {
            color: theme.palette.text.mainLableBold,
            fontWeight: "600",
            fontSize: "13px",
            display: "inline-flex"
        },
        "& .MuiBreadcrumbs-separator":{
            color :"#32529D"
        }
    }
});