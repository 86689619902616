import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import Typography from '@material-ui/core/Typography';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    identity: {
      width: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      display: 'inline-flex',
      justifyContent: 'space-between',
      '& svg': {
        marginLeft: '0.3em',
      },
      alignItems: 'center',
      borderRadius: '8px',
      padding: '6px 14px 6px 6px',
    },
    infoContainer: {
      zIndex: 2,
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    typeography: {
      color: 'white',
      marginLeft: '8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.688rem',
      },
    },
  })
);
interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isSelected,
  isLocalParticipant,
  hideParticipant,
}: ParticipantProps) {
  const classes = useStyles();
  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;

  return (
    isLocalParticipant ? <>
      <ParticipantInfo
        participant={participant}
        onClick={onClick}
        isSelected={isSelected}
        isLocalParticipant={isLocalParticipant}
        hideParticipant={hideParticipant}
      >
        <ParticipantTracks
          participant={participant}
          videoOnly={videoOnly}
          enableScreenShare={enableScreenShare}
          isLocalParticipant={isLocalParticipant}
          videoType="localRef"
        />
      </ParticipantInfo>
      <div className={classes.infoContainer}>
        <div style={{ display: 'flex', width: '100%' }}>
          <div className={classes.identity}>
            <Typography variant="body1" className={classes.typeography} component="span">
              {'(You)'}
            </Typography>
            <AudioLevelIndicator audioTrack={audioTrack} />
          </div>
        </div>
      </div>
    </> : <></>
  );
}
