import { MenuItem } from '@material-ui/core';
import React from 'react';
import styles from "./styles";
import { genericObj } from "../../../models/common";
import CheckIcon from '@mui/icons-material/Check';

const CommonMenuItem = React.forwardRef((props: genericObj, ref: any) => {
    const classes = styles();
    return (
        <MenuItem {...props} className={classes.menuItem} ref={ref}>
            <div className={classes.content}>{props.children}</div> 
            {props.selected && <CheckIcon />}
        </MenuItem>
    );
})
export default CommonMenuItem;