import * as actionTypes from "../actions/actionTypes";
const initialState = {
    isShowConsultation: false
};
const showConsultation = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_CONSULTATION:
      const isShow = action.isShowConsultation;
      return { ...state,  isShowConsultation: isShow};
      
    default:
      return state;
  }
};

export default showConsultation;