export var followUp = {
    isFollowUp:false,
    appointmentType:"FOLLOW_UP",
    modeOfBooking:"TELEBOOKING",
    appointmentCreatedBy: "",
    appointmentCreatedPersonRole: "",
    state:"APPOINTMENT_REQUESTED",
    consultationFee:0,
    claimAmount:0,
    slot:"",
    dayOfTheWeek:0,
    date:"",
    slotStartTime: "",
    isTelemedicine:true,
    parentAppointmentId:""
}

export const setfollowUp = (value) =>{
    followUp = {...followUp,...value}
}

export const getfollowUp = () => {
    return followUp;
}