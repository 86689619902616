import React from "react";
import PropTypes from "prop-types";

import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import { useStyles, StyledTableCell } from "../TableStyle";

const TableHeader = (props) => {
  const classes = useStyles();
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {props.sortingRequired && headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
                headCell.label
              )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableHeader.defaultProps = {
  sortingRequired: true,
};

TableHeader.propTypes = {
  headCells: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func,
  sortingRequired: PropTypes.bool,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
};

export default TableHeader;
